<template>
  <div>
    <div class="my-1 d-flex justify-content-between">
      <b-button
        @click="$router.go(-1)"
        variant="outline-warning"
        class="btn-icon"
        size="sm"
      >
        <span class="mb-6">RETOUR</span>
      </b-button>
    </div>

    <div>
      <!-- Block titre start -->
      <div>
        <b-card>
          <b-card-title class="mb-0 row">
            <span class="col1 ml-2 d-flex">
              <b-badge
                class="mr-1"
                style="position: relative; top: -1.5px"
                variant="primary"
              >
                1
              </b-badge>
              INFORMATIONS
            </span>

            <div class="col border-bottom mx-2 mb-1"></div>

            <span class="col1 mr-2 float-right d-flex">
              <b-badge
                class="mr-1"
                style="position: relative; top: -1.5px"
                variant="primary"
              >
                2
              </b-badge>
              INVENTAIRE {{ associationName ? " de " + associationName : "" }}
            </span>
          </b-card-title>
        </b-card>
      </div>
      <!-- Block Titre end -->

      <b-card>
        <!-- Block date & time start -->
        <div v-if="showFormInventaire">
          <!-- form start -->
          <validation-observer
            ref="formInventaire"
            class="d-flex justify-content-center"
          >
            <b-form class="col mt-2" @submit.prevent>
              <b-row>
                <b-form-group class="col" v-if="dernier_inventaire">
                  <validation-provider
                    #default="{ errors }"
                    name="date_dernier_inventaire"
                    rules="required"
                  >
                    <label for="code">Date de début </label>
                    <b-form-input disabled v-model="dernier_inventaire">
                    </b-form-input>
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : errorsLastDateInventaire
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="date cloture inventaire"
                    rules="required"
                  >
                    <label for="code">Cloture de cet inventaire </label>
                    <flat-pickr
                      disabled
                      v-model="inventairesData.to"
                      @click="resetInputCurrentDateInventaire"
                      class="form-control"
                      :config="{
                        defaultDate: defaultDate,
                        time_24hr: true,
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        maxDate: maxDate,
                        maxTime: maxTime,
                      }"
                    />
                    <!-- <b-form-input disabled v-model="inventairesData.to">
                    </b-form-input> -->
                    <!-- defaultDate: nowDate, -->
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : errorsCurrentDateInventaire
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>

              <!-- submit button start -->
              <div class="mt-2">
                <b-button
                  v-if="etatButtonInventaire"
                  variant="primary"
                  type="submit"
                  @click.prevent="getInventaire"
                >
                  Continuer
                </b-button>
                <b-button v-else variant="primary">
                  <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                  ></b-icon-arrow-clockwise>
                  En cours
                </b-button>
              </div>
              <!-- submit button end -->
            </b-form>
          </validation-observer>
          <!-- form end -->
        </div>
        <!-- Block date & time end -->

        <!-- Block inventaire start -->
        <div v-else>
          <div class="row mb-2">
            <b-input-group class="ml-1" prepend="BENEFICE">
              <cleave
                id="benefice"
                v-model="benefice"
                class="form-control col-4"
                disabled
                :raw="false"
                :options="numberFormat"
              />
            </b-input-group>
          </div>

          <b-alert class="mt-2" :show="checkBenef" variant="danger">
            <div class="alert-body d-flex flex justify-space-between">
              <span class="mx-auto text-uppercase"
                >Veuillez répartir le bénéfice entre les associés.</span
              >
            </div>
          </b-alert>

          <div class="mt-2">
            <!-- table start -->
            <div class="row mb-2">
              <div class="col" v-if="!checkBenef">
                <!-- Enregistrer un inventaire start -->
                <b-button
                  v-if="etatButtonSaveInventaire"
                  variant="primary"
                  type="submit"
                  @click="saveInventaire"
                >
                  Enregistrer
                </b-button>
                <b-button v-else variant="primary">
                  <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                  ></b-icon-arrow-clockwise>
                  En cours
                </b-button>
                <!-- Enregistrer un inventaire end -->
              </div>
            </div>
            <b-table-simple hover medium caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Nom & Prénom(s)</b-th>
                  <b-th>Ancien Capital</b-th>
                  <b-th>Retrait</b-th>
                  <b-th>Bénéfice</b-th>
                  <b-th>Nouveau Capital</b-th>
                  <!-- <b-th>Actions</b-th> -->
                </b-tr>
              </b-thead>
              <b-tbody>
                <!-- v-if="item.type == 'associe'" -->
                <b-tr v-for="(item, index) in repartition" :key="index">
                  <b-th>{{ item.name }} </b-th>
                  <b-td>{{ $thousandSeparator(item.capital) }}</b-td>
                  <b-td>{{ $thousandSeparator(item.retrait) }}</b-td>
                  <b-td>
                    <cleave
                      v-model="item.benefice"
                      :options="numberFormat"
                      @input="beneficeItem(item)"
                      class="form-control"
                      :raw="false"
                    />
                  </b-td>
                  <b-td>
                    {{ $thousandSeparator(item.new_capital) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <!-- <b-table
              id="my-table"
              class="border-bottom mt-1"
              responsive
              show-empty
              :fields="headers"
              :items="associesList"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(capitalPre)="data">
                {{ $thousandSeparator(data.item.pivot.cap_invest) }}
              </template>

              <template #cell(benefice)="data">
                <cleave
                  v-model="data.item.benefice"
                  :options="numberFormat"
                  class="form-control"
                  placeholder="0"
                  :raw="false"
                  @input="beneficeItem(data.item)"
                />
              </template>

              <template #cell(retrait)="data">
                {{
                  (data.item.retrait = $thousandSeparator(
                    retraitsList.find(
                      (_retrait) => _retrait.associe == data.item.name
                    )
                      ? retraitsList.find(
                          (_retrait) => _retrait.associe == data.item.name
                        ).total
                      : 0
                  ))
                }}
              </template>

              <template #cell(newCapital)="data">
                {{ $thousandFormater(data.item.newCapital) }}
              </template>

              <template #empty>
                <div class="text-center">
                  <span class="text-nowrap">Aucune donnée existante</span>
                </div>
              </template>
            </b-table> -->
            <!-- table end -->
          </div>
        </div>
        <!-- Block inventaire end -->
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
//import BCard from "@core/components/b-card/BCard.vue";
import { json2excel, excel2json } from "js2excel";
import { ModelListSelect } from "vue-search-select";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";

import {
  BCard,
  BTable,
  BCardTitle,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPlus,
  BIconPencilFill,
  BIconInfoCircleFill,
  BIconTrashFill,
  BIconStar,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormDatalist,
  BInputGroupPrepend,
  BInputGroupAppend,
  BIconArrowClockwise,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormCheckbox,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BTd,
  BTr,
  BTfoot,
} from "bootstrap-vue";

export default {
  components: {
    BTableSimple,
    BTbody,
    BThead,
    BCardTitle,
    BTh,
    BTd,
    BFormCheckbox,
    BTr,
    BTfoot,
    BBadge,
    Cleave,
    BCard,
    BTable,
    BAvatar,
    BModal,
    BMedia,
    BMediaAside,
    ModelListSelect,
    BCardText,
    BMediaBody,
    BAlert,
    BCardCode,
    BFormDatalist,
    BPagination,
    BButton,
    BTooltip,
    BIconInfoCircleFill,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    BIconArrowClockwise,
    BInputGroup,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BFormSelectOption,
    BIconStar,
    ComboBoxComponent,
    ComboBoxPlugin,
    flatPickr,
  },

  data() {
    return {
      associationName: null,
      dernier_inventaire: null,
      etatButtonInventaire: true,
      etatButtonSaveInventaire: true,
      showFormInventaire: true,
      errorsLastDateInventaire: null,
      errorsCurrentDateInventaire: null,
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      inventairesData: {
        associationToFilter: 0,
        to: "",
      },
      perPage: 10,
      currentPage: 1,
      // filter: null,
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      queryReponse: [],
      devises: [],
      association: {},
      repartition: [],
      associesList: [],
      retraitsList: [],
      accountsList: [],
      acheteurs_associes: [],
      inventaireData: {},
      headers: [
        {
          key: "name",
          label: "Nom Associés",
          sortable: true,
        },
        {
          key: "capitalPre",
          label: "Capital Précédent",
          sortable: true,
        },
        {
          key: "benefice",
          label: "Bénéfices",
          sortable: true,
        },
        {
          key: "retrait",
          label: "Retrait effectué",
          sortable: true,
        },
        {
          key: "newCapital",
          label: "Nouveau Capital",
          sortable: true,
        },
      ],
    };
  },

  computed: {
    checkBenef() {
      let montant = 0;
      if (this.repartition && this.repartition.length) {
        this.repartition.forEach((element) => {
          montant += this.$thousandFormater(element.benefice);
        });
      }

      if (montant == this.benefice) {
        return false;
      }
      return true;
    },
    defaultDate() {
      const now = new Date();
      const toDay =
        now.getFullYear() + "-" + (now.getMonth() +1) + "-" + now.getDate();
      const toTime = now.getHours() + ":" + now.getMinutes();
      const defaultDate = toDay + " " + toTime;
      this.inventairesData.to = defaultDate;
      return defaultDate;
    },

    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },
    nowDate() {
      let date = new Date();
      let annee = date.getFullYear();
      let mois = ("0" + (date.getMonth() + 1)).slice(-2);
      let jour = ("0" + date.getDate()).slice(-2);
      let heure = ("0" + date.getHours()).slice(-2);
      let minute = ("0" + date.getMinutes()).slice(-2);
      date = `${annee}-${mois}-${jour} ${heure}:${minute}`;
      return date;
    },
    maxTime() {
      let now = new Date();
      let heure = ("0" + now.getHours()).slice(-2);
      let minute = ("0" + now.getMinutes()).slice(-2);
      now = `${heure}:${minute}`;
      return now;
    },
    benefice() {
      // Calcul Bénéfice = (valeur du stock + mt en caisse + reste à recouvrer + mt comptes + retaits associés) - (Dédommagements + CI + Frais + Chargeur + Génération facture) - Capital précédent
      let montantComptes = 0;

      this.accountsList.forEach((element) => {
        montantComptes +=
          element.solde *
          this.devises.find((_dev) => _dev.name == element.devise).value;
      });

      let montantRetraits = this.retraitsList.reduce(function (sum, current) {
        return sum + current.total * 1;
      }, 0);

      let benefice =
        this.inventaireData.valeur_stock +
        this.inventaireData.cash +
        this.inventaireData.reste_sur_avance +
        montantComptes +
        montantRetraits -
        this.inventaireData.total_dettes -
        parseInt(this.inventaireData.capital_precedent);

      return benefice;
    },
  },

  created() {
    this.getDevisesList();
    this.getParams();
  },

  methods: {
    beneficeItem(item) {
      return (item.new_capital =
        this.$thousandFormater(item.capital) +
        this.$thousandFormater(item.benefice) -
        this.$thousandFormater(item.retrait));
    },

    saveInventaire() {
      if (!this.checkBenef) {
        this.etatButtonSaveInventaire = false;

        this.$bvModal
          .msgBoxConfirm(
            "Vous êtes sur le point d'effectuer un inventaire. Êtes-vous sûr de vouloir continuer ?",
            {
              title: "Enregistrer un inventaire",
              okVariant: "danger",
              cancelVariant: "outline-secondary",
              okTitle: "Confirmer",
              cancelTitle: "Annuler",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.operationDo();
            } else {
              this.etatButtonSaveInventaire = true;
            }
          });
      }
    },

    async operationDo() {
      let nouveauCap = 0;
      let totaRetrait = 0;
      let montantComptes = 0;
      this.repartition.forEach((element) => {
        nouveauCap += this.$thousandFormater(element.new_capital);
        totaRetrait += this.$thousandFormater(element.retrait);
      });

      this.accountsList.forEach((element) => {
        montantComptes +=
          element.solde *
          this.devises.find((_dev) => _dev.name == element.devise).value;
      });
      this.queryReponse.repartition = this.repartition;
      this.queryReponse.nouveau_capital = nouveauCap;
      this.queryReponse.total_retraits = totaRetrait;
      this.queryReponse.montant_comptes = montantComptes;
      this.queryReponse.benefice_net = this.benefice;
      await this.$http
        .post("/inventaires", this.queryReponse)
        .then((response) => {
          if (!response.data.success) {
            this.etatButtonSaveInventaire = true;
            this.showToast("L'enregistrement n'a pas aboutit !", "danger");
          } else {
            this.showToast("Inventaire enregistré avec succès !", "success");
            this.etatButtonSaveInventaire = true;
            window.sessionStorage.setItem(
              "association_id",
              this.association.id
            );
            localStorage.setItem("association_name", this.association.name);
            this.$router.push({
              name: "associations-show",
              params: {
                id: this.association.id,
                association_id: this.association.id,
              },
            });
          }
        })
        .catch((e) => {
          this.etatButtonSaveInventaire = true;
          this.showToast(e.response.data.message, "danger");
        });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    getDevisesList() {
      this.$http.get("/devises").then((response) => {
        this.devises = response.data.data;
        // this.devisePrincipal = this.devises.find(
        //   ({ devise_principale }) => devise_principale === 1
        // );
      });
    },

    getParams() {
      this.dernier_inventaire =
        this.$router.currentRoute.params.dernier_inventaire;
      this.associationName = this.$router.currentRoute.params.association;
    },

    async getInventaire() {
      this.$refs.formInventaire.validate().then((success) => {
        if (success) {
          this.inventairesData.associationToFilter =
            this.$router.currentRoute.params.id;
          this.etatButtonInventaire = false;

          this.$http
            .post("/inventaire-period", this.inventairesData)
            .then((response) => {
              if (!response.data.success) {
                this.etatButtonInventaire = true;
                this.showToast(response.data.message, "danger");
              } else {
                let dataAssociation = response.data.data.association;
                this.association = dataAssociation;

                this.resetInputInventaire();

                this.queryReponse = response.data.data;
                this.etatButtonInventaire = true;
                this.showFormInventaire = false;

                this.inventaireData.cash = this.queryReponse.cash;
                this.inventaireData.valeur_stock =
                  this.queryReponse.valeur_stock;
                this.inventaireData.reste_sur_avance =
                  this.queryReponse.reste_sur_avance;
                this.inventaireData.capital_precedent =
                  this.queryReponse.capital_precedent;
                this.inventaireData.total_dettes =
                  this.queryReponse.total_cartes_imp +
                  this.queryReponse.total_chargeurs +
                  this.queryReponse.total_frais +
                  this.queryReponse.refunds +
                  this.queryReponse.total_generation_facture;
                this.queryReponse.to = this.inventairesData.to;

                if (dataAssociation.acheteurs_associes) {
                  this.acheteurs_associes = dataAssociation.acheteurs_associes;
                }
                if (dataAssociation.retraits) {
                  this.retraitsList = dataAssociation.retraits.reverse();
                }

                if (dataAssociation.associes) {
                  this.associesList = dataAssociation.associes.reverse();
                  this.associesList.forEach((element) => {
                    this.repartition.push({
                      benefice: 0,
                      id: element.id,
                      name: element.name,
                      retrait: this.retraitsList.find(
                        (_retrait) => _retrait.associe == element.name
                      )
                        ? this.retraitsList.find(
                            (_retrait) => _retrait.associe == element.name
                          ).total
                        : 0,
                      capital: parseFloat(element.pivot.cap_invest),
                      new_capital: 0,
                    });
                  });
                }

                if (dataAssociation.comptes) {
                  dataAssociation.comptes.forEach((account) => {
                    let associeName = {
                      name: this.acheteurs_associes.find(
                        (_ass) => _ass.id == account.author_id
                      ).name,
                    };

                    let toMerge = {
                      ...associeName,
                      ...account,
                    };

                    this.accountsList.push(toMerge);
                  });
                }
              }
            })
            .catch((e) => {
              console.log("e", e);

              let err = e.response.data.errors;

              this.etatButtonInventaire = true;

              if (err) {
                if (err.to) {
                  this.errorsCurrentDateInventaire = err.to[0];
                }
              }
            });
        }
      });
    },

    resetInputInventaire() {
      this.resetInputLastDateInventaire();
      this.resetInputCurrentDateInventaire();
    },

    resetInputLastDateInventaire() {
      if (this.errorsLastDateInventaire) {
        this.errorsLastDateInventaire = null;
      }
    },

    resetInputCurrentDateInventaire() {
      if (this.errorsCurrentDateInventaire) {
        // this.errorsCurrentDateInventaire = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
</style>
